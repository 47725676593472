import { Track } from '@volvo-cars/tracking';
import React from 'react';
import { getDealerId } from '../../utils/volvoTrackingHelpers';
import { useStore } from '../providers/StoreProvider';
import { Retailer } from '../types/retailer';
import styles from './RetailerCard.TapArea.module.css';

type TapAreaProps = React.PropsWithChildren<{
  retailer: Retailer;
}>;

export const TapArea = ({ children, retailer }: TapAreaProps) => {
  const { dispatch } = useStore();

  return (
    <Track
      eventAction="card|select"
      eventLabel="retailer card"
      customData={{
        dealerId: getDealerId(retailer),
        dealerInfo: retailer?.name ?? undefined,
      }}
    >
      <button
        className={`${styles.boxContent} tap-area flex-col gap-16 text-start w-full items-stretch -m-8 p-8 rounded`}
        onClick={() =>
          dispatch({
            type: 'SET_SELECTED_RETAILER',
            payload: { ...retailer },
          })
        }
        type="button"
      >
        {children}
      </button>
    </Track>
  );
};
