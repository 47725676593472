import * as React from 'react';
import { Retailer } from '../types/retailer';

type RetailerListProps = {
  scrollRef?: React.RefObject<HTMLUListElement>;
  retailers: Retailer[];
  retailerCardRender: (retailer: Retailer) => React.ReactNode;
};

export const RetailerList = ({
  scrollRef,
  retailers,
  retailerCardRender,
}: RetailerListProps) => {
  return (
    <ul className="flex-col gap-16 relative" ref={scrollRef}>
      {retailers.map((retailer) => (
        <li
          key={retailer.parmaPartnerCode || '' + retailer.distanceFromPointKm}
        >
          {retailerCardRender(retailer)}
        </li>
      ))}
    </ul>
  );
};
