import { useBreakpoints } from '@vcc-www/hooks';
import { useDialog } from '@volvo-cars/react-headless';
import React, { useEffect, useState } from 'react';
import { SearchBlockWidth } from '../constants/styling';
import { MapPositioned } from '../map/MapPositioned';
import { SearchInputProvider } from '../providers/SearchInputProvider';
import { useStore } from '../providers/StoreProvider';
import { RetailerCardMaxi } from '../retailer-card/retailer-card-maxi/RetailerCardMaxi';
import { RetailerCardMaxiDialog } from '../retailer-card/retailer-card-maxi/RetailerCardMaxiDialog';
import { SearchFrame } from '../search/SearchFrame';
import styles from './FullPageContainer.module.css';

type FullPageContainerProps = {
  scrollRef?: React.RefObject<HTMLDivElement>;
  retailersList: React.ReactNode;
  retailerCardMaxiContent?: React.ReactNode;
  retailerSearch: React.ReactNode;
  initialSearchValue?: string;
  onSearchValueChange?: (searchValue: string) => void;
};

export const FullPageContainer = ({
  scrollRef,
  retailersList,
  retailerCardMaxiContent,
  retailerSearch,
  initialSearchValue,
  onSearchValueChange,
}: FullPageContainerProps) => {
  // Needed to toggle status of expanded view modal on mobile / non mobile view.
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilL, fromL } = useBreakpoints();
  const { selectedRetailer, retailersListVisible, expandedCardEnabled, view } =
    useStore();
  const [expandedCardVisible, setExpandedCardVisible] = useState(false);

  const onDialogToggle = ({ open }: { open: boolean }) => {
    if (!open) closeExpandedCard();
  };
  const { dialogProps, showDialog, closeDialog } = useDialog({
    onToggle: onDialogToggle,
  });

  useEffect(() => {
    const mapElement = document.querySelector('[aria-roledescription="map"]');

    if (!expandedCardEnabled) return;
    if (expandedCardVisible && untilL) {
      // This is fix for avoiding autofocus on the dialog backbutton. Because the map is in focus, it triggers a safaribug that is fixed in dialogcode, see https://github.com/volvo-cars/design-system-web/blob/master/packages/react-headless/src/dialog.ts#L221
      // You can still get the focusring when tabbing, because the pin is in focus before opening the dialog and not the map
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus') &&
        mapElement.blur();
      mapElement instanceof HTMLElement &&
        mapElement?.matches(':focus-visible') &&
        mapElement.blur();
      showDialog();
    } else {
      mapElement?.setAttribute('tabindex', '0');
      closeDialog();
    }
  }, [
    expandedCardVisible,
    showDialog,
    closeDialog,
    untilL,
    expandedCardEnabled,
  ]);

  const closeExpandedCard = () => {
    setExpandedCardVisible(false);
  };
  useEffect(() => {
    if (selectedRetailer) {
      setExpandedCardVisible(true);
    }
  }, [selectedRetailer]);

  const showMaxiCard =
    expandedCardVisible && selectedRetailer && expandedCardEnabled && fromL;

  return (
    <>
      <SearchInputProvider
        initialSearchValue={initialSearchValue}
        onSearchValueChange={onSearchValueChange}
      >
        <div
          className={`${styles.searchWrapper} ${view === 'map' ? styles.searchWrapperMap : 'md:mt-24'} flex-col w-full lg:min-h-0 mx-auto lg:m-auto sticky top-0`}
          style={{ '--search-block-width': `${SearchBlockWidth}px` }}
        >
          <SearchFrame
            scrollRef={scrollRef}
            header={
              showMaxiCard ? (
                <RetailerCardMaxi onClose={closeExpandedCard}>
                  {retailerCardMaxiContent}
                </RetailerCardMaxi>
              ) : (
                retailerSearch
              )
            }
            main={
              !showMaxiCard &&
              retailersListVisible && (
                <>
                  {!!retailersList && retailersListVisible && (
                    <div className={`${styles.listWrapper} until-lg:hidden`}>
                      {retailersList}
                    </div>
                  )}
                </>
              )
            }
          />
          {view === 'list' && retailersListVisible && (
            <div className="flex-grow lg:hidden py-24 px-24 overflow-auto scrollbar-none">
              {retailersList}
            </div>
          )}
        </div>
        <RetailerCardMaxiDialog
          dialogProps={dialogProps}
          onClose={closeExpandedCard}
          retailerCardMaxiContent={retailerCardMaxiContent}
        />
      </SearchInputProvider>
      <MapPositioned view={view} />
    </>
  );
};
