import { LatLngLiteralVerbose } from '@googlemaps/google-maps-services-js';
import { useMemo } from 'react';
import { formUrl } from '../../utils/formUrl';
import { isValidLatitudeLongitude } from '../../utils/isValidCoordinates';
import { PLACE_COORDINATES_ENDPOINT } from '../constants/apiEndpoints';
import { useFetch } from './useFetch';

type PlaceCoordinatesResponse = {
  status: number;
  error: string;
  data?: { coordinates: LatLngLiteralVerbose; zipCodes: string[] };
};
type PlaceCoordinatesError = {
  status: number;
  error: string;
};

type UseCoordinatesProps = {
  coordinates?: {
    longitude: number;
    latitude: number;
  };
  place_id?: string | undefined;
  sessiontoken: string | undefined;
};

export const useCoordinates = ({
  coordinates: initialCoordinates,
  place_id,
  sessiontoken,
}: UseCoordinatesProps) => {
  const url = formUrl(PLACE_COORDINATES_ENDPOINT, {
    place_id,
    sessiontoken,
  });
  const isValidCoordinates = useMemo(
    () => initialCoordinates && isValidLatitudeLongitude(initialCoordinates),
    [initialCoordinates],
  );

  const { data, isLoading, error } = useFetch<
    PlaceCoordinatesResponse,
    PlaceCoordinatesError
  >(!isValidCoordinates && place_id ? url : null, [
    place_id,
    isValidCoordinates,
  ]);

  const coordinates = useMemo(
    () => initialCoordinates || data?.data?.coordinates,
    [initialCoordinates, data?.data?.coordinates],
  );
  return {
    coordinates,
    isLoading,
    error,
  };
};
