import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import React from 'react';
import { Map } from './Map';
import { MapErrorBoundary } from './MapErrorBoundary';

type MapPositionedProps = {
  view: 'map' | 'list';
};

const FallBack = () => {
  const translate = useSharedComponentsTranslate();

  return (
    <div className="px-8 my-24 flex-col justify-center items-center w-full h-full gap-24">
      <p>
        {translate('RetailerSelector.map.mapErrorBoundaryFallback') ||
          'An error occurred while loading the map. Please try again by refreshing the page.'}
      </p>
      <button
        type="button"
        className="button-filled"
        onClick={() => window.location.reload()}
      >
        {translate('RetailerSelector.map.mapErrorBoundaryFallbackButton') ||
          'Refresh'}
      </button>
    </div>
  );
};

export const MapPositioned = ({ view }: MapPositionedProps) => (
  <>
    {/* 
      Two statements needed, only rendering desktop in view map will not allow for toggling to list view -> resizing screen 
      Rendering map with checking view as pins are not loading correctly on tablet view unless this statement (bug in useCluster, wrong latestMapChangeEventValue is sent)
      Once that bug is solved, one statement without view==="map" is required
     */}
    {view === 'map' && (
      <div className="h-full -mt-8 md:mt-0 lg:hidden">
        <MapErrorBoundary fallback={<FallBack />}>
          <Map />
        </MapErrorBoundary>
      </div>
    )}
    <div className="h-full until-lg:hidden">
      <MapErrorBoundary fallback={<FallBack />}>
        <Map />
      </MapErrorBoundary>
    </div>
  </>
);
