import { useTracker } from '@volvo-cars/tracking';
import React, { useCallback } from 'react';
import { TRACKING_CATEGORY } from '../../tracking';
import { getDealerId } from '../../utils/volvoTrackingHelpers';
import { useStore } from '../providers/StoreProvider';
import { ClusterPin } from './ClusterPin';
import styles from './Pin.module.css';
import { SinglePin } from './SinglePin';
import { updateMapPanBoundsZoom } from './mapUtils/updateMapPanBoundsZoom';
import { useClusters } from './useClusters';
import { useOffset } from './useOffset';

type Clusters = ReturnType<typeof useClusters>;
type PinProps = {
  cluster: Clusters[number];
  map: google.maps.Map | null;
  pinVisibility: boolean;
  lat: number /* Needed for positioning the marker in map */;
  lng: number /* Needed for positioning the marker in map */;
};

export const Pin = ({ cluster, map, pinVisibility }: PinProps) => {
  const {
    key,
    geometry: { coordinates },
    properties: { retailer },
  } = cluster;
  const lng = coordinates?.[0];
  const lat = coordinates?.[1];

  const { dispatch, view, expandedCardEnabled } = useStore();
  const offset = useOffset();

  const track = useTracker();
  const handleClusterClick = useCallback(() => {
    if (!map) return;
    const zoom = cluster.expansionZoom;
    updateMapPanBoundsZoom(map, {
      lat,
      lng,
      zoom,
      offset,
    });
  }, [map, cluster, lat, lng, offset]);

  const handleSingleClick = useCallback(() => {
    dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });
    if (view === 'map' && !expandedCardEnabled) {
      dispatch({ type: 'SET_VIEW', payload: 'list' });
    }

    retailer &&
      dispatch({ type: 'SET_SELECTED_RETAILER', payload: { ...retailer } });

    track.customEvent({
      eventAction: 'card|select',
      eventCategory: TRACKING_CATEGORY,
      eventLabel: 'retailer marker',
      dealerId: getDealerId(retailer),
      dealerInfo: retailer?.name ?? undefined,
    });
  }, [dispatch, retailer, track, expandedCardEnabled, view]);

  if (!map) return null;
  return (
    <div className={`${styles.wrapper} w-fit h-fit`}>
      {!cluster.properties.cluster ? (
        <SinglePin
          visible={pinVisibility}
          key={key}
          lng={lng}
          lat={lat}
          retailer={cluster.properties.retailer}
          onClick={handleSingleClick}
        />
      ) : (
        <ClusterPin
          count={cluster.properties?.point_count || 0}
          onClick={handleClusterClick}
        />
      )}
    </div>
  );
};
