import {
  LatLngLiteral,
  LatLngLiteralVerbose,
} from '@googlemaps/google-maps-services-js';
import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isValidLatitudeLongitude } from '../../utils/isValidCoordinates';
import { useStore } from '../providers/StoreProvider';
import { AorRetailer, Retailer } from '../types/retailer';
import { Capability } from '../types/retailerCapabilities';

type SortRetailersProps = {
  retailers: Retailer[];
  coordinates: LatLngLiteralVerbose | undefined;
  refreshSessiontoken: any;
  capabilities: Capability[];
  aorRetailers?: AorRetailer[];
  aorIsLoading?: boolean;
};

export function useSortRetailers({
  coordinates,
  capabilities,
  retailers,
  aorRetailers,
  aorIsLoading,
}: SortRetailersProps): Retailer[] {
  const { roadLengthUnit } = useCurrentMarketSite();
  const { dispatch } = useStore();
  const [sortedRetailers, setSortedRetailers] = useState<Retailer[]>(retailers);
  const [sortingDone, setSortingDone] = useState(false);

  // If Aor Is loading, set resetSortingDone
  useEffect(() => {
    if (aorIsLoading) {
      setSortingDone(false);
    }
  }, [aorIsLoading]);

  // If we have new retailers or placeId change, remove the selected retailer
  useEffect(() => {
    // To not trigger reset on initial load
    !!retailers.length &&
      !!coordinates &&
      dispatch({ type: 'SET_SELECTED_RETAILER', payload: null });
  }, [coordinates, retailers, dispatch]);

  // When sorting is complete, start showing retailer-list.
  useEffect(() => {
    if (coordinates && sortingDone && !aorIsLoading) {
      dispatch({ type: 'SET_RETAILERSLIST_VISIBLE', payload: true });
    }
  }, [coordinates, dispatch, sortingDone, aorIsLoading]);

  const isValidCoordinates =
    coordinates && isValidLatitudeLongitude(coordinates);
  const earthRadius = roadLengthUnit === 'mile' ? 3958.8 : 6371; // earths radius in km/miles
  const sortRetailersCallback = useCallback(() => {
    const retailersSorted = sortRetailers(
      retailers,
      isValidCoordinates
        ? {
            lng: coordinates.longitude,
            lat: coordinates.latitude,
          }
        : undefined,
      earthRadius,
      aorRetailers?.length ? aorRetailers[0].parmaPartnerCode : null,
    );
    setSortedRetailers(retailersSorted);
  }, [aorRetailers, coordinates, earthRadius, isValidCoordinates, retailers]);

  useEffect(() => {
    if (retailers.length && coordinates && !aorIsLoading) {
      setSortingDone(false);
      sortRetailersCallback();
      setSortingDone(true);
    }
  }, [retailers, coordinates, aorIsLoading, sortRetailersCallback]);

  const filteredRetailers = useMemo(() => {
    return capabilities?.length === 0
      ? sortedRetailers
      : sortedRetailers.filter((retailer) => {
          return retailer.capabilities.some((c) => capabilities.includes(c));
        });
  }, [capabilities, sortedRetailers]);

  return filteredRetailers;
}

export function sortRetailers(
  retailers: Retailer[],
  coordinates: LatLngLiteral | undefined,
  radius: number,
  priorityParmaPartnerCode?: string | null | undefined,
) {
  const origin: [number, number] = [
    coordinates?.lat || 0,
    coordinates?.lng || 0,
  ];
  const retailersWithDistance = retailers.map((retailer) => {
    if (!retailer.latitude || !retailer.longitude || !coordinates?.lat)
      return { ...retailer };
    const distanceValue = getDistance(
      origin,
      [parseFloat(retailer.latitude), parseFloat(retailer.longitude)],
      radius,
    );
    const distanceFromPointKm = distanceValue;
    return {
      ...retailer,
      distanceFromPointMiles: distanceFromPointKm * 1.60934,
      distanceFromPointKm,
    };
  });

  return !priorityParmaPartnerCode
    ? retailersWithDistance.sort((a, b) => {
        return a.distanceFromPointKm - b.distanceFromPointKm;
      })
    : retailersWithDistance.sort((a, b) => {
        if (a.parmaPartnerCode === priorityParmaPartnerCode) return -1;
        if (b.parmaPartnerCode === priorityParmaPartnerCode) return 1;
        return a.distanceFromPointKm - b.distanceFromPointKm;
      });
}

// lat/lng to distance. Haversine formula used by retailer api
export function getDistance(
  [lat1, lng1]: [number, number],
  [lat2, lng2]: [number, number],
  radius: number,
) {
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(lat1)) *
      Math.cos(degToRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return radius * c;
}

const degToRad = (deg: number) => deg * (Math.PI / 180);
