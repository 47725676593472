import React, { ErrorInfo } from 'react';
import { ErrorMessages } from '../../errorMessages';
import { logError } from '../logError';

type MapErrorBoundaryProps = {
  children?: React.ReactNode;
  fallback?: React.ReactNode; // Optional fallback prop
};

type MapErrorBoundaryState = {
  hasError: boolean;
};

export class MapErrorBoundary extends React.Component<
  MapErrorBoundaryProps,
  MapErrorBoundaryState
> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(
      `${ErrorMessages.MAP_UI_ERROR}:  ${error} ${errorInfo.componentStack ? `with component trace: ${errorInfo.componentStack}` : ''} `,
    );
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    } else {
      return this.props.children;
    }
  }
}
