import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import React from 'react';
import styles from './RetailerCard.PhoneNumber.module.css';

type PhoneNumberProps = {
  ariaLabelRetailerName: string;
  phoneNumber?: string;
  isClickable?: boolean;
};

export const PhoneNumber = ({
  ariaLabelRetailerName,
  phoneNumber,
  isClickable = false,
}: PhoneNumberProps) => {
  const translate = useSharedComponentsTranslate();
  const commonClasses = 'font-16 flex gap-8 items-center';
  const phoneNumberContent = (
    <>
      <Icon icon="phone" size={16} />
      {phoneNumber}
    </>
  );

  return phoneNumber ? (
    /* Needed to not trigger card opening on click, but only phone number call */
    <div role="presentation" onClick={(e) => e.stopPropagation()}>
      <Track eventLabel="phone number">
        {isClickable ? (
          <a
            href={`tel:${phoneNumber}`}
            aria-label={
              translate('RetailerSelector.retailerCard.phoneNumberAriaLabel', {
                retailerName: ariaLabelRetailerName,
                phoneNumber,
              }) || `${ariaLabelRetailerName} Phone: ${phoneNumber}`
            }
            className={styles.link + ' ' + commonClasses}
            data-testid="dealer:phoneNumber"
          >
            {phoneNumberContent}
          </a>
        ) : (
          <p className={commonClasses}>{phoneNumberContent}</p>
        )}
      </Track>
    </div>
  ) : null;
};
