import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import * as React from 'react';
import { getCombinedOpeningHours } from '../../utils/getCombinedOpeningHours';
import {
  FormattedSpecialHours,
  getFormattedSpecialHours,
} from '../../utils/getFormattedSpecialHours';
import { OpeningHours as GQLOpeningHours } from '../types/graphql';

type OpeningHoursProps = {
  openingHours: GQLOpeningHours;
  firstDayOfWeek?: 'monday' | 'sunday';
  locale: string;
};

export const OpeningHours = ({
  openingHours: openingHoursData,
  firstDayOfWeek = 'monday',
  locale,
}: OpeningHoursProps) => {
  const translate = useSharedComponentsTranslate();
  const { siteSlug } = useCurrentMarketSite();

  const { specialDates, ...openingHours } = openingHoursData;
  const combinedOpeningHours = getCombinedOpeningHours({
    openingHours,
    locale,
    combineDays: true,
    firstDayOfWeek,
    hour12: siteSlug === 'us',
  });
  const formattedSpecialHours: FormattedSpecialHours[] =
    getFormattedSpecialHours(specialDates, locale);

  return (
    <>
      <div>
        {combinedOpeningHours
          .filter((day) => day.hours && day.hours?.length > 0)
          .map(({ days, hours }, index) => (
            <div className="flex justify-between" key={index}>
              <p className="text-secondary">
                {days.endDay
                  ? `${days.startDay} - ${days.endDay}`
                  : days.startDay}
              </p>
              <div className="flex-col items-end">
                {hours?.map((h, i) => (
                  <p key={i} className="text-secondary">
                    {h}
                  </p>
                ))}
              </div>
            </div>
          ))}
        {formattedSpecialHours.length > 0 && (
          <div className="mt-24">
            <p className="font-medium">
              {translate('RetailerSelector.retailerCard.specialHours') ||
                'Special hours'}
            </p>
            {formattedSpecialHours.map(({ date, timings }, index) => (
              <div className="flex justify-between" key={index}>
                <p className="text-secondary">{date}</p>
                <div className="flex-col items-end">
                  {timings?.map((time, i) => (
                    <p key={i} className="text-secondary">
                      {time}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
