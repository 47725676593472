import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { useDialog } from '@volvo-cars/react-headless';
import { Icon } from '@volvo-cars/react-icons';
import { Track } from '@volvo-cars/tracking';
import React, { useState } from 'react';
import { useRetailers } from '../providers/RetailersProvider';
import { FilterSheet } from './FilterSheet';

export const Filter = () => {
  const [reset, setReset] = useState(false);
  const onToggle = () => {
    setReset((prev) => !prev);
  };
  const translate = useSharedComponentsTranslate();
  const { dialogProps, showDialog, closeDialog } = useDialog({ onToggle });
  const { activeCapabilityFilters } = useRetailers();

  return (
    <>
      <Track eventLabel="open-filters">
        <button
          type="button"
          className="button-filled p-16 gap-8"
          aria-label={
            translate('RetailerSelector.search.filterButtonAriaLabel') ||
            'Filter'
          }
          onClick={showDialog}
        >
          <Icon icon="filter" filled color="always-white" size={16} alt="" />
          {activeCapabilityFilters.length || undefined}
        </button>
      </Track>
      <FilterSheet
        onClose={closeDialog}
        dialogProps={dialogProps}
        resetToggle={reset}
      />
    </>
  );
};
